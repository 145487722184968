import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Box, Button, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppSelector } from "../../../hooks/useStoreHook";
import MUISelect from "../../Controls/Select";
import { CustomerPayload } from "../../../interfaces/User";
import { FlatOrgStructureNode } from "../../../interfaces/OrgStructure";
import { redirectionURLs } from "../../../utilis/redirectionURLs";

interface AddUserFormProps {
  handleSubmit: (values: CustomerPayload) => void;
  parentNodes: FlatOrgStructureNode[];
}

export default function AddUserForm({
  handleSubmit,
  parentNodes,
}: AddUserFormProps) {
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const schema = yup.object().shape({
    email: yup.string().required("Required").email("Email format is required"),
    first_name: yup.string().required("Required"),
    last_name: yup.string().required("Required"),
    org_structure_assignments: yup.string().nullable(),
    redirect_uri: yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={{
        email: "",
        first_name: "",
        last_name: "",
        org_structure_assignments: "",
        redirect_uri: ""
      }}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="email"
            id="email"
            label="Email*"
            fullWidth
            margin="normal"
            name="email"
            error={Boolean(errors.email && touched.email)}
            helperText={errors.email && touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            size="small"
          />
          <TextField
            type="text"
            id="first_name"
            label="First Name*"
            fullWidth
            margin="normal"
            name="first_name"
            error={Boolean(errors.first_name && touched.first_name)}
            helperText={
              errors.first_name && touched.first_name && errors.first_name
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
            size="small"
          />
          <TextField
            type="text"
            id="last_name"
            label="Last Name*"
            fullWidth
            margin="normal"
            name="last_name"
            error={Boolean(errors.last_name && touched.last_name)}
            helperText={
              errors.last_name && touched.last_name && errors.last_name
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
            size="small"
          />
          <Box my={2}>
            <Box component="h4" mb={0}>
              Organisation Assignments
            </Box>
            <MUISelect
              value={values.org_structure_assignments}
              label={"Parent Node"}
              name="org_structure_assignments"
              handleChange={handleChange}
              handleBlur={handleBlur}
              keyItem="uuid"
              keyValue="name"
              list={parentNodes}
              labelId={"label-parent_node_uuid"}
              id={"select-parent_node_uuid"}
              styles={{ mt: 2 }}
              setFieldValue={setFieldValue}
              renderValue={() => {
                const parentNode = parentNodes?.find(
                  (node) => node.uuid === values.org_structure_assignments,
                );
                return <>{parentNode?.name}</>;
              }}
            />
          </Box>
          <Box component="h4" mb={0}>
             Redirection URI
            </Box>
            <span>Please select where should the user be redirected after verify account.</span>
          <MUISelect
            error={
              errors.redirect_uri && touched.redirect_uri && errors.redirect_uri
            }
            label={"Redirect URI"}
            dataTestid={"redirect_uri"}
            required
            value={values.redirect_uri}
            name="redirect_uri"
            handleChange={handleChange}
            handleBlur={handleBlur}
            keyItem="id"
            keyValue="name"
            list={redirectionURLs}
            labelId={"redirect_uri"}
            id={"redirect_uri"}
            styles={{ mt : 2 }}
          />
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
