export enum REDIRECTION_URLS_ENUM {
  SPILL_DASHBOARD = 1,
  SPILL_MOBILE_APP = 2,
  CENTRAL_PLATFORM = 3,
};

export const redirectionURLs = [
  {
    id: REDIRECTION_URLS_ENUM.SPILL_DASHBOARD,
    name: "Spill dashboard",
    redirect_uri: process.env.REACT_APP_REDIRECT_URL_SPILL_DASHBOARD,
    client_id: process.env.REACT_APP_CLIENT_ID_SPILL_DASHBOARD,
  },
  {
    id: REDIRECTION_URLS_ENUM.SPILL_MOBILE_APP,
    name: "Spill mobile app",
    redirect_uri: process.env.REACT_APP_LOGIN_REDIRECT_URL_SPILL_MOBILE_APP,
    client_id: process.env.REACT_APP_CLIENT_ID_SPILL_MOBILE_APP,
  },
  {
    id: REDIRECTION_URLS_ENUM.CENTRAL_PLATFORM,
    name: "Central platform",
    redirect_uri: process.env.REACT_APP_LOGIN_REDIRECT_URL_CENTRAL_PLATFORM,
    client_id: process.env.REACT_APP_CLIENT_ID_CENTRAL_DASHBOARD,
  },
];
