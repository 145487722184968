import { useNavigate } from "react-router-dom";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { Box, Button, Grid, TextField } from "@mui/material";
import MUISelect from "../../Controls/Select";
import { getCountryDialWithCountryCode } from "../../../utilis/country_dial_info";
import { PARENT_NODE_NOTE, validate } from "../../../utilis/shared";
import { SiteFormValues, SiteResponse } from "../../../interfaces/Site";
import { Country } from "../../../interfaces/Address";
import { OrgStructureNode } from "../../../interfaces/OrgStructure";

interface EditSiteFormProps {
  site: SiteResponse;
  handleSubmitSite: (value: SiteFormValues) => void;
  countries: Country[];
  parentNodes: OrgStructureNode[];
}

export default function EditSiteForm({
  site,
  handleSubmitSite,
  countries,
  parentNodes,
}: EditSiteFormProps) {
  const navigate = useNavigate();

  const initialFormValues: SiteFormValues = {
    name: site.name,
    phone: site.phones[0].number.replace(
      getCountryDialWithCountryCode(site.phones[0].country_code, true),
      "",
    ),
    country_code: site.phones[0].country_code,
    address_1: site.address.address_1,
    address_2: site.address.address_2,
    country: site.address?.country_uuid,
    county: site.address.county,
    postcode: site.address.postcode,
    city: site.address.city,
    parent_node_uuid: site.parent_node_uuid,
  };

  const schema: yup.SchemaOf<SiteFormValues> = yup.object().shape({
    name: yup.string().required("Required"),
    country_code: yup.mixed().required("Required"),
    phone: yup
      .string()
      .required("Required")
      .matches(/^[+]*[0-9]*$/g, "Please enter numbers"),
    address_1: yup.string().required("Required"),
    address_2: yup.string().nullable(),
    country: yup.string().required("Required"),
    county: yup.string().required("Required"),
    postcode: yup.string().required("Required"),
    city: yup.string().required("Required"),
    parent_node_uuid: yup.string().required("Required"),
  });

  const renderCountryValue = (values) => {
    const country = countries.find(
      (country) => country.uuid === values.country,
    );
    return (
      <>
        {country.flag} {country.name}
      </>
    );
  };

  const renderParentNodeValue = (values) => {
    const parentNode = parentNodes?.find(
      (node) => node.uuid === values.parent_node_uuid,
    );
    return <>{parentNode?.name}</>;
  };

  const handleCountryCodeChange = (e, form) => {
    const country_code = e.target.value;
    form.setFieldValue("country_code", country_code);
  };

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={(values: SiteFormValues) => handleSubmitSite(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <Box mt={1} component={"h4"}>
            Site Details
          </Box>
          <TextField
            type="text"
            id="site_name"
            label="Name*"
            fullWidth
            margin="normal"
            name="name"
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            size="small"
            data-testid="site_name"
          />
          <Grid container spacing={2} sx={{ py: 1 }}>
            <Grid item xs={3}>
              <Field validate={validate} name="country_code">
                {({ form }: any) => (
                  <MUISelect
                    error={
                      errors.country_code &&
                      touched.country_code &&
                      errors.country_code
                    }
                    value={values.country_code}
                    label={"code"}
                    required
                    name="country_code"
                    handleChange={(e) => handleCountryCodeChange(e, form)}
                    handleBlur={handleBlur}
                    keyItem="code"
                    keyValue="flag"
                    keyAppend="dial_code"
                    list={countries}
                    labelId={"label-code"}
                    id={"select-code"}
                    dataTestid="country_code"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={9}>
              <TextField
                type="text"
                id="site-phone"
                label="Phone*"
                fullWidth
                name="phone"
                error={Boolean(errors.phone && touched.phone)}
                helperText={errors.phone && touched.phone && errors.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                inputProps={{
                  min: 0,
                }}
                size="small"
                data-testid="site_phone"
              />
            </Grid>
          </Grid>
          <Box my={2} component={"h4"}>
            Address Details
          </Box>
          <TextField
            required
            id="site-address-line-1"
            label="Address line 1"
            fullWidth
            margin="normal"
            name="address_1"
            error={Boolean(errors.address_1 && touched.address_1)}
            helperText={
              errors.address_1 && touched.address_1 && errors.address_1
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address_1}
            size="small"
            data-testid="site_address_1"
          />
          <TextField
            id="site-address-line-2"
            label="Address line 2"
            fullWidth
            margin="normal"
            name="address_2"
            error={Boolean(errors.address_2 && touched.address_2)}
            helperText={
              errors.address_2 && touched.address_2 && errors.address_2
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address_2}
            size="small"
            data-testid="site_address_2"
          />
          <TextField
            required
            id="site-city"
            label="City"
            fullWidth
            margin="normal"
            name="city"
            error={Boolean(errors.city && touched.city)}
            helperText={errors.city && touched.city && errors.city}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            size="small"
            data-testid="site_city"
          />
          <TextField
            id="site-county"
            label="State / Province / Region*"
            fullWidth
            margin="normal"
            name="county"
            error={Boolean(errors.county && touched.county)}
            helperText={errors.county && touched.county && errors.county}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.county}
            size="small"
            data-testid="site_county"
          />
          <TextField
            required
            id="site-postcode"
            label="Zip / Postal Code"
            fullWidth
            margin="normal"
            name="postcode"
            error={Boolean(errors.postcode && touched.postcode)}
            helperText={errors.postcode && touched.postcode && errors.postcode}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.postcode}
            sx={{ mb: 3 }}
            size="small"
            data-testid="site_postcode"
          />
          <MUISelect
            error={errors.country && touched.country && errors.country}
            value={values.country}
            label={"Country"}
            name="country"
            handleChange={handleChange}
            handleBlur={handleBlur}
            keyItem="uuid"
            keyValue="name"
            keyPreAppend="flag"
            list={countries}
            labelId={"label-country"}
            id={"select-country"}
            renderValue={() => renderCountryValue(values)}
            dataTestid="country"
          />
          <MUISelect
            error={
              errors.parent_node_uuid &&
              touched.parent_node_uuid &&
              errors.parent_node_uuid
            }
            value={values.parent_node_uuid || ""}
            label={"Parent Node"}
            required
            name="parent_node_uuid"
            handleChange={handleChange}
            handleBlur={handleBlur}
            keyItem="uuid"
            keyValue="name"
            list={parentNodes}
            labelId={"label-parent_node_uuid"}
            id={"select-parent_node_uuid"}
            styles={{ mt: 2 }}
            renderValue={() => renderParentNodeValue(values)}
            dataTestid="parent_node"
          />
          <p>
            *Note: {PARENT_NODE_NOTE}
          </p>
          <Grid container spacing={1} mt={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
                data-testid="submit_btn"
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
                data-testid="cancel_btn"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
