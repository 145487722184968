import * as React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Tooltip } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { sendCustomerInviteEmail } from "../../../apis/v1/UserManagement";
import { showSnackbar } from "../../../utilis/snackbar";
import MUISelect from "../../Controls/Select";
import { redirectionURLs, REDIRECTION_URLS_ENUM } from "../../../utilis/redirectionURLs";
import { LoadingButton } from "@mui/lab";

export default function ResendInviteEmail({ userId }) {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const schema = yup.object().shape({
    redirect_uri: yup.string().required("Required"),
  });

  const handleClose = (
    event: React.SyntheticEvent<unknown>,
    reason?: string,
  ) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const resendInviteEmail = (values) => {
    setLoading(true);
    const selected_redirect_uri = redirectionURLs.find(url => url.id === values.redirect_uri)
    const redirect_uri = selected_redirect_uri.id !== REDIRECTION_URLS_ENUM.SPILL_MOBILE_APP ? selected_redirect_uri.redirect_uri : null
    sendCustomerInviteEmail(userId, redirect_uri)
      .then((res) => {
        if (res) {
          showSnackbar("Email sent successfully", "success");
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log("Error during send invite email", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box display={"inline-block"}>
      <Tooltip title="Resend invite email" placement="top">
        <IconButton size="small" onClick={() => setOpen(true)} sx={{ mr: 0.5 }}>
          <EmailIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Resend Invite Email</DialogTitle>
        <Formik
          initialValues={{
            redirect_uri: "",
          }}
          validationSchema={schema}
          onSubmit={(values: any) => resendInviteEmail(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }: any) => (
            <>
              {console.log(values, errors)}
              <DialogContent>
                <Box
                  component="form"
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  <Box component="h4" mb={0}>
                    Redirection URI
                  </Box>
                  <span>
                    Please select where should the user be redirected after
                    verify account.
                  </span>
                  <MUISelect
                    error={
                      errors.redirect_uri &&
                      touched.redirect_uri &&
                      errors.redirect_uri
                    }
                    label={"Redirect URI"}
                    dataTestid={"redirect_uri"}
                    required
                    value={values.redirect_uri}
                    name="redirect_uri"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    keyItem="id"
                    keyValue="name"
                    list={redirectionURLs}
                    labelId={"redirect_uri"}
                    id={"redirect_uri"}
                    styles={{ mt: 2 }}
                  />
                </Box>
              </DialogContent>
              <DialogActions sx={{ px:3 }}>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  onClick={handleSubmit}
                  data-testid={"submit_btn"}
                >
                  Submit
                </LoadingButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
}
